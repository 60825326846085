<template>
  <nav class="bg-white shadow-md p-4">
    <div class="container mx-auto flex justify-between items-center">
      <div class="flex items-center justify-between">
        <img src="../assets/logotam.png" alt="Tam Logo" class="h-14 mr-10">
        <ul class="flex space-x-6">
          <!-- Agregamos el pseudo elemento para el subrayado con animación -->
          <li class="relative group">
            <a href="#" class="text-blue-900 hover:text-blue-500 font-bold text-xl pb-2">Reservar</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <a href="#" @click="toggleDropdown" class="text-blue-900 hover:text-blue-500 font-bold text-xl pb-2">Ofertas y destinos</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <a href="#" class="text-blue-900 hover:text-blue-500 font-bold text-xl pb-2">Tu reserva</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
          <li class="relative group">
            <a href="#" class="text-blue-900 hover:text-blue-500 font-bold text-xl pb-2">Información y ayuda</a>
            <span class="absolute left-0 bottom-[-6px] w-0 h-1 bg-gradient-to-r from-blue-400 to-blue-800 transition-all duration-500 group-hover:w-full"></span>
          </li>
        </ul>
      </div>
      <img src="../assets/escudo.jpeg" alt="escudo" class="h-14 ml-6">
    </div>

    <DropdownMenu v-if="isDropdownOpen" @close="isDropdownOpen = false" />
  </nav>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue';

export default {
  components: {
    DropdownMenu
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
};
</script>
