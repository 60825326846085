<template>
  <div class="my-6">
    <!-- Dropdown para seleccionar la ciudad -->
    <div class="flex justify-center">
      <div class="relative inline-block text-left">
        <!-- Texto y botón en la misma línea -->
        <div class="flex items-center gap-2">
          <div class="text-lg font-bold">Ofertas desde</div>
          <button @click="toggleDropdown" class="inline-flex justify-between text-lg py-2 items-center ml-2 font-bold bg-yellow-400 px-3 rounded-xl">
            {{ selectedCity || 'La Paz' }}
            <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </div>
        
        <!-- Menú de opciones para seleccionar una ciudad -->
        <div v-if="dropdownOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a @click="selectCity('La Paz')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">La Paz</a>
            <a @click="selectCity('Santa Cruz')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Santa Cruz</a>
            <a @click="selectCity('Cobija')" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Cobija</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Mostrar las imágenes de la ciudad seleccionada -->
    <div v-if="selectedCity" class="mt-6">
      <h2 class="text-center text-xl font-bold mb-4 font-serif">Destinos de {{ selectedCity }}</h2>
      <div class="grid grid-cols-3 gap-4 px-5">
        <img v-for="(image, index) in cityImages[selectedCity]" :key="index" :src="image" alt="Imagen" class="w-full h-80 object-cover rounded-lg shadow-md">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownOpen: false,
      selectedCity: 'La Paz', // Selección predeterminada de "La Paz"
      cityImages: {
        'La Paz': [
          require('@/assets/lp-1.jpg'),
          require('@/assets/lp-2.jpg'),
          require('@/assets/lp-3.jpg'),
        ],
        'Santa Cruz': [
          require('@/assets/santa-1.jpeg'),
          require('@/assets/santa-2.jpeg'),
          require('@/assets/santa-3.jpeg'),
        ],
        'Cobija': [
          require('@/assets/cobija-1.jpeg'),
          require('@/assets/cobija-2.jpeg'),
          require('@/assets/cobija-3.jpeg'),
        ],
      },
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectCity(city) {
      this.selectedCity = city;
      this.dropdownOpen = false; // Cerrar el menú desplegable después de seleccionar
    },
  },
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí si es necesario */
</style>
