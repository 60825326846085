<template>
    <div class="flex justify-center items-center mt-5 md:mx-32 sm:mx-5 ">
      <video
        class="h-44 rounded-2xl"
        autoplay
        muted
        playsinline
        loop
      >
        <source src="../assets/ruta-avion.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoTam'
  }
  </script>
  
  <style scoped>
  /* Add any additional styles here */
  </style>