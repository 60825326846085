<template>
    <footer class="bg-white p-8">
      <div class="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        
        <!-- Sección Información legal -->
        <div>
          <h4 class="font-semibold text-blue-900 mb-4">INFORMACION LEGAL</h4>
          <ul>
            <li><router-link to="/condiciones-transporte" class="text-gray-600 hover:text-blue-900">Condiciones del contrato de transporte</router-link></li>
            <li><router-link to="/politica-privacidad" class="text-gray-600 hover:text-blue-900">Política de privacidad</router-link></li>
            <li><router-link to="/seguridad-privacidad" class="text-gray-600 hover:text-blue-900">Seguridad y privacidad</router-link></li>
          </ul>
        </div>
        
        <!-- Sección Portales asociados -->
        <div>
          <h4 class="font-semibold text-blue-900 mb-4">LINK INSTITUCIONALES</h4>
          <ul>
            <li><router-link to="/ministerio-defensa" class="text-gray-600 hover:text-blue-900">Ministerio de Defensa</router-link></li>
            <li><router-link to="/ejercito-bolivia" class="text-gray-600 hover:text-blue-900">Ejército de Bolivia</router-link></li>
          </ul>
        </div>
        
        <!-- Sección Redes Sociales -->
        <div>
          <h4 class="font-semibold text-blue-900 mb-4">REDES SOCIALES</h4>
          <div class="flex space-x-4 mb-4">
            <!-- Iconos de redes sociales -->
            <a href="https://www.facebook.com/tamEPagenciadeviajes" class="text-blue-900">
              <img :src="facebookLogo" alt="Facebook" class="w-6 h-6" />
            </a>
            <a href="https://twitter.com" class="text-blue-900">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://youtube.com" class="text-blue-900">
              <img :src="youtubeLogo" alt="YouTube" class="w-6 h-6" />
            </a>
            <a href="https://www.tiktok.com/@tam_ep?_t=8nJjt1UovNV&_r=1" class="text-blue-900">
              <img :src="tiktokLogo" alt="TikTok" class="w-6 h-6" />
            </a>
          </div>
          <h4 class="font-semibold text-blue-900 mb-4">CONTACTOS</h4>
          <img :src="logo" alt="Certificaciones" class="w-24 h-auto" />
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  import facebookLogo from '../assets/imagenes/facebook.png'; // Asegúrate de que la ruta sea correcta
  import youtubeLogo from '../assets/imagenes/youtube.png'; 
  import tiktokLogo from '../assets/imagenes/tiktok.png';
  import logo from '../assets/imagenes/Logo.png';
  
  export default {
    name: "FootPage",
    data() {
      return {
        facebookLogo,
        youtubeLogo,
        tiktokLogo,
        logo
      };
    },
  };
  </script>
  
  <style scoped>
  /* Agrega aquí los estilos si es necesario */
  </style>
  