<template>
  <div class="absolute bg-white backdrop-blur-md bg-opacity-45 shadow-lg mt-2 py-4 w-full">
    <div class="container mx-auto grid grid-cols-3 gap-4">
      <!-- Primer bloque del menú -->
      <div>
        <ul class="space-y-2">
          <li><a href="#" class="text-gray-700 hover:text-red-500">Ofertas de vuelos</a></li>
          <li><a href="#" class="text-gray-700 hover:text-red-500">Destinos</a></li>
        </ul>
      </div>

      <!-- Segundo bloque del menú -->
      <div>
        <ul class="space-y-2">
          <!-- <li><a href="#" class="text-gray-700 hover:text-red-500">Reserva de hoteles</a></li>
          <li><a href="#" class="text-gray-700 hover:text-red-500">Alquiler de autos</a></li> -->
          <li><a href="#" class="text-gray-700 hover:text-red-500">Tours y excursiones</a></li>
          <li><a href="#" class="text-gray-700 hover:text-red-500">Traslados</a></li>
        </ul>
      </div>

      <!-- Tercer bloque con el carrusel de imágenes -->
      <div>
        <div class="relative">
          <!-- Carrusel de imágenes -->
           <div class="">
             <img :src="currentImage" alt="Carrusel" class="w-full h-48 object-cover rounded-lg transition-transform duration-400 ease-in-out">
           </div>
          <div class="absolute bottom-2 left-2 text-white bg-red-500 px-2 py-1 rounded-lg">
            ¡Nueva ruta!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('@/assets/la-paz-1.png'),
        require('@/assets/la-paz-2.png'),
        require('@/assets/la-paz-3.png'),
        require('@/assets/santa-cruz-1.png'),
        require('@/assets/santa-cruz-2.png'),
        require('@/assets/santa-cruz-3.png'),
        require('@/assets/cobija-1.png'),
        require('@/assets/cobija-2.png'),
        require('@/assets/cobija-3.png'),
      ],
      currentImageIndex: 0,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    }
  },
  mounted() {
    // Cambia la imagen cada 5 segundos
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 5000);
  },
};
</script>

<style scoped>

</style>