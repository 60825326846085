<template>
  <div class="bg-cover bg-repeat" :style="{ backgroundImage: `url(${require('@/assets/fondotam.jpeg')})` }">
    <Navbar />
    <VideoTam />
    <CitySelector />
    <FootPage />
  </div>
</template>


<script>
import Navbar from './components/NavBar.vue';
import CitySelector from './components/CitySelector.vue';
import VideoTam from './components/VideoTam.vue';
import FootPage from './components/FootPage.vue';
export default {
  components: {
    Navbar,
    CitySelector,
    VideoTam,
    FootPage
  }
};
</script>
